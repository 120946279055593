<template>
  <div>
    <div class="flex md:flex-row gap-2.5">
      <div class="bg-gray-100 p-3 font-medium text-base rounded-md flex items-center w-full" v-if="isShowcaseMode">
        <InformationCircleIcon class="h-4 w-4 mr-2.5"/>
        {{ showcaseDescription }}
      </div>
      <template v-else>
        <div class="mb-2 md:ml-2 hidden sm:block" v-if="quantity !== 0">
          <FormInputCounter v-model="cartQuantity" :min="1" :max="maxQtyForProduct(product)" :step="quantityStep"/>
        </div>
        <div class="w-full mb-2 md:ml-2 hidden sm:block">
          <div v-if="quantity <= 0" class="btn-sold">
            <StockOutText />
          </div>
          <FormButton
              v-if="quantity > 0"
              color="secondary"
              type="button"
              class="uppercase w-full"
              :loading="addCartLoading"
              @click="addToBasket(selectedVariant.variantId, cartQuantity, selectedVariant?.primaryStockData.rawCurrency, selectedVariant?.primaryStockData.warehouseId, null, true, false, finalPrice)"
          >
            {{ $t('app.add-to-cart') }}
          </FormButton>
        </div>

        <!-- Mobile buttons -->
        <div class="w-full sm:hidden fixed sm:top-auto sm:right-auto bottom-0 left-0 sm:col-span-1 z-50">
          <div class="py-2.5 px-4 bg-white/30 backdrop-blur-md border border-gray-200 flex justify-between">
            <div v-if="quantity <= 0" class="btn-sold">
              <StockOutText />
            </div>
            <FormInputCounter v-model="cartQuantity" :min="1" :max="quantity" :step="quantityStep" v-if="quantity > 0"/>
            <FormButton
                v-if="quantity > 0"
                color="secondary"
                type="button"
                class="uppercase w-full"
                :loading="addCartLoading"
                @click="addToBasket(selectedVariant.variantId, cartQuantity, selectedVariant?.primaryStockData.rawCurrency, selectedVariant?.primaryStockData.warehouseId, null, true, false, finalPrice)"
            >
              {{ $t('app.add-to-cart') }}
            </FormButton>
          </div>
        </div>
      </template>
    </div>
    <div v-if="status === 'authenticated'">
      <FormButton
          type="button"
          size="sm"
          @click="addWishlist(selectedVariant)"
          :aria-label="$t('listing.add-to-wishlist')"
      >
        <template v-if="selectedVariant?.isFavorited">
          <HeartFilledIcon class="text-red w-5 h-5"/>
          <span class="pl-2">{{ $t('listing.remove-from-wishlist') }}</span>
        </template>
        <template v-if="!selectedVariant?.isFavorited">
          <HeartIcon class="text-primary w-5 h-5"/>
          <span class="pl-2">{{ $t('listing.add-to-wishlist') }}</span>
        </template>
      </FormButton>
    </div>

    <div v-if="status === 'unauthenticated'">
      <FormButton
          type="button"
          size="sm"
          @click="authModalIsVisible = true"
          :aria-label="$t('listing.add-to-wishlist')"
      >
        <HeartIcon class="text-primary w-5 h-5"/>
        <span class="pl-2">{{ $t('listing.add-to-wishlist') }}</span>
      </FormButton>
    </div>

    <AppWidgetBuyViaWhatsApp/>
  </div>

</template>

<script setup>
import {HeartIcon, ShoppingBagIcon, InformationCircleIcon} from "@heroicons/vue/24/outline";
import {HeartIcon as HeartFilledIcon} from "@heroicons/vue/24/solid";
import StockOutText from "~/components/StockOutText.vue";

const {
  product,
  selectedVariant,
  cartQuantity,
  isShowcaseMode,
  showcaseDescription,
  finalPrice
} = await useProduct()

const {
  addCartLoading,
  addToBasket
} = await useBasket()

const {
  addWishlist
} = useWishlist()

const {maxQtyForProduct} = useListing()

const {status} = await useAuth();
const {isWholesaleUser, authModalIsVisible} = await useCustomer();

const quantity = computed(() => {
  return parseInt(selectedVariant.value?.primaryStockData?.quantity)
})

const quantityStep = computed(() => {
  return selectedVariant.value?.primaryStockData?.quantityStep
})

</script>

<style scoped>

</style>